<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import store from "@/state/store";
import HrEmployeesPage from "./HrEmployees/HrEmployeesPage.vue";
import HrAttendancePage from "./HrAttendance/HrAttendancePage.vue";
// var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    HrEmployeesPage,
    HrAttendancePage,
  },
  data() {
    return {
      // Start Employee Records

      // state: state,
      // End Employee Records

      // End Empolyee attendance
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.hr.subItems.hr_personal_records')"
    />

    <!-- start tabs block -->
    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <!-- start employees tab -->
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employee_kpi_records.employees") }}
          </span>
        </template>
        <hr-employees-page />
      </b-tab>
      <!-- end employees tab -->

      <!-- start attendance tab -->
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employee_kpi_records.attendance") }}
          </span>
        </template>
        <hr-attendance-page />
      </b-tab>
      <!-- end attendance tab -->
    </b-tabs>
    <!-- end tabs block -->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
